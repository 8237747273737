.wrapper {
  width: 460px;
  margin: 40px 0;
}

.logo {
  width: 125px;
  height: 14px;
  margin-bottom: 8px;
}

.wrapper h1 {
  font-weight: 500;
  font-size: 20px;
  color: #2c2e35;
  margin-bottom: 8px;
}

.wrapper p {
  color: #fe5b37;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 24px;
}

.wrapper p img {
  width: 16px;
  height: 16px;
}

.formContainer {
  display: flex;
  gap: 8px;
  justify-content: space-between;
}
.limits {
  color: #2C2E35;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 8px;
}

@media (max-width: 1024px) {
  .wrapper {
    width: 448px;
  }
}

@media (max-width: 768px) {
  .wrapper {
    width: 100%;
  }
}

