.wrapper {
  width: 460px;
}

.logo {
  width: 125px;
  height: 14px;
  margin-bottom: 8px;
}

.wrapper h1 {
  font-weight: 500;
  font-size: 20px;
  color: #2c2e35;
  margin-bottom: 24px;
}

.form p {
  color: #fe5b37;
  font-size: 14px;
  font-weight: 400;
}

@media (max-width: 1024px) {
  .wrapper {
    width: 448px;
  }
}

@media (max-width: 768px) {
  .wrapper {
    width: 100%;
  }
}
