.modal {
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  z-index: 99999;
}

.modal.active {
  opacity: 1;
  pointer-events: all;
}

.modal_content {
  padding: 24px;
  border-radius: 20px;
  background-color: white;
  width: 300px;
  height: fit-content;
}

.modal_content.active {
  transform: scale(1);
}

.close_modal_cross_img {
  width: 1.5vw;
  min-width: 1vw;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 0.5vw;
  margin-top: 1vh;
  height: auto;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .modal_content {
    padding: 24px;
    margin: 80px 168px !important;
  }
  .close_modal_cross_img {
    width: 20px;
    height: auto;
    margin: 10px;
  }
}
@media (max-width: 767px) {
  .modal_content {
    margin: 0 16px 0 16px !important;
  }
}
