.wrapper {
  display: flex;
  flex-direction: column;
  width: 460px;
  align-items: center;
}
.wrapper h1 {
  font-weight: 500;
  font-size: 20px;
  color: #2c2e35;
  margin-bottom: 24px;
}
.statusImg {
  width: 48px;
  height: 48px;
  margin-bottom: 12px;
}

.logo {
  width: 125px;
  height: 14px;
}

@media (max-width: 1024px) {
  .wrapper {
    width: 448px;
  }
}

@media (max-width: 768px) {
  .wrapper {
    width: 100%;
  }
}
