.wrapper {
  width: 460px;
}
.wrapper h1 {
  font-weight: 500;
  font-size: 20px;
  color: #2c2e35;
  margin-bottom: 24px;
}
.statusImg {
  width: 48px;
  height: 48px;
  margin-bottom: 12px;
}

.logo {
  width: 125px;
  height: 14px;
}

.block {
  margin-bottom: 24px;
}
.block img {
  height: fit-content;
}

.block span {
  color: #8e95b0;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 4px;
  text-align: left;
}

.block p {
  color: #2c2e35;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.link{
  background-color: #1890ff;
  border: 2px solid #1890ff;
  padding: 8px 16px;
  border-radius: 4px;
  text-align: center;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  opacity: 1;
  transition: 0.3s;
  text-decoration: none;
  width: 100%;
  margin-bottom: 4500000px;
}
.link:hover {
  border: 2px solid #1890ff;
  opacity: 0.5;
  cursor: pointer;
  outline: none;
}
@media (max-width: 1024px) {
  .wrapper {
    width: 448px;
  }
}

@media (max-width: 768px) {
  .wrapper {
    width: 100%;
  }
}

